.overview-box {
  .overview-status {
    font-weight: 500;
    border-radius: 2px;
    color: $color-white;
  }
}

.widget-list {
  @include reset-list;

  > li {
    border-bottom: 1px solid $color-light-grey;

    &:last-child {
      border-bottom: 0 none;
    }

    .person-item {
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .widget-list-item-radius,
    .person-tag {
      border-radius: 2px;
      color: $color-white;
      font-weight: 500;
    }

    .p-progressbar {
      height: 6px;
      border-radius: 10px;
      background: $color-white;
    }

    .p-progressbar-value {
      background: $color-yellow;
    }
  }
}

.widget-expenses {
  .card-subheader {
    font-size: 0.9rem;
    color: $color-secondary;
    border-bottom: 1px solid $color-light-grey;
  }

  .item {
    padding: 0.5rem;
    border-bottom: 1px solid $color-light-grey;

    &:last-child {
      border: 0 none;
    }

    .type {
      color: #0097a7;
      font-size: 1.5rem;
    }

    .value {
      font-weight: 500;
    }

    .subtext {
      color: $color-secondary;
    }

    .item-button {
      a {
        color: $color-secondary;
      }
    }
  }
}

.widget-traffic {
  .left {
    padding: 1rem;

    .total {
      .title {
        color: $color-secondary;
      }

      .value {
        font-size: 2rem;
        font-weight: 500;
      }
    }

    .info {
      .title {
        color: $color-secondary;
      }

      .value {
        font-weight: 500;
      }

      .percent {
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.5;

        i {
          &.type-green {
            color: #0097a7;
          }
          &.type-orange {
            color: $color-yellow;
          }
          &.type-gray {
            color: #455a64;
          }
        }
      }
    }
  }
}

.widget-overlay {
  position: relative;

  .overlay-header {
    position: relative;
    height: 20rem;
    top: -3rem;
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }

  .description {
    line-height: 1.5;
  }
}

.widget-topsearchs {
  .item {
    padding: 1rem;
    border-bottom: 1px solid $color-light-grey;

    .value {
      font-weight: 500;

      &.type-green {
        color: $color-success;
      }

      &.type-yellow {
        color: $color-yellow;
      }

      &.type-pink {
        color: $color-primary;
      }
    }

    &:nth-child(even) {
      background-color: $color-white;
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }
}

.widget-timeline {
  .p-timeline.p-timeline-vertical .p-timeline-event-opposite {
    flex: 0;
    padding: 0;
  }

  .p-timeline-event-separator {
    .marker-icon {
      color: white;
    }

    .custom-marker {
      border-radius: 4px;
    }
  }
}

.widget-activity {
  @include reset-list;

  > li {
    padding: 1rem 0;
    border-bottom: 1px solid $color-light-grey;

    &:last-child {
      border-bottom: 0 none;
    }

    &:nth-child(1) {
      .p-progressbar-value {
        background: $color-yellow;
      }
    }

    &:nth-child(2) {
      .p-progressbar-value {
        background: $color-primary;
      }
    }

    &:nth-child(3) {
      .p-progressbar-value {
        background: $color-success;
      }
    }

    &:nth-child(4) {
      .p-progressbar-value {
        background: $color-success;
      }
    }

    &:nth-child(5) {
      .p-progressbar-value {
        background: $color-success;
      }
    }

    &:nth-child(6) {
      .p-progressbar-value {
        background: $color-primary;
      }
    }

    .activity-item {
      .activity-title {
        font-weight: 500;
      }

      .activity-subtext {
        font-size: 0.85rem;
        color: $color-secondary;
      }
      .p-progressbar {
        height: 6px;
        border-radius: 10px;
        background: $color-white;
      }
    }
  }
}

.widget-bestsellers {
  @include reset-list;

  > li {
    padding: 1rem 0;

    &:last-child {
      border-bottom: 0 none;
    }

    .bestseller-item {
      background-color: $color-white;
      border-radius: 4px;
      height: 3.5rem;
      transition: box-shadow 0.2s;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      a {
        color: $color-secondary;
      }

      .item-button {
        margin-left: auto;
      }

      &:hover {
        cursor: pointer;
        background-color: $color-secondary-light;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
          0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }
}
