.p-paginator.p-component {
  justify-content: flex-end;
  font-size: 0.875rem;
  color: $color-main-text !important;
  border: none;

  .p-paginator-next,
  .p-paginator-prev {
    min-width: 2rem;
    height: 2rem;
  }

  .p-paginator-last,
  .p-paginator-first {
    &.p-paginator-element {
      display: none;
    }
  }

  .p-dropdown {
    height: 2rem;

    .p-dropdown-label {
      padding-top: 0.5rem;
    }
  }

  .p-element {
    font-size: 0.875rem;
    color: $color-main-text !important;

    &.p-paginator-page {
      min-width: 2rem;
      height: 2rem;
      border-radius: 0.5rem;
      display: none;
    }
  }
}
