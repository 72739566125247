body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
  margin: 0;
  color: $color-main-text;
  font-weight: 400;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'DM Sans', sans-serif !important;
}

.p-component,
.p-inputtext {
  font-family: 'Roboto', sans-serif !important;
}

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.color-error {
  color: $color-red;
}

.color-success {
  color: $color-success;
}
.no-wrap {
  white-space: nowrap;
}

.p-avatar.portrait img {
  width: 100% !important;
  height: auto !important;
}

.p-avatar.landscape img {
  width: auto !important;
  height: 100% !important;
}
