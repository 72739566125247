.p-tabview {
  color: $color-primary;

  .p-tabview-nav-content {
    overflow-x: initial;
    overflow-y: initial;
  }

  .p-tabview-nav-container {
    width: fit-content;
  }

  .p-tabview-nav {
    border-radius: 6px;
    border: 1px solid $color-light-grey2;
    background-color: $color-light-grey2;
    padding: 1px;

    > li {
      background: $color-light-grey2;
      border: 1px solid transparent;
      border-radius: 6px;

      &:not(:last-child) {
        margin-right: 1px;
      }

      .p-tabview-nav-link {
        color: $color-secondary-text;
        height: 36px;
        padding: 8px 24px;
        font-size: 14px;
        line-height: 20px;
        background: $color-light-grey2;
        border-width: 1px;
        border-color: $color-light-grey2;
        outline: none;
        margin: 0;
        border-radius: 0;

        &:focus:not(.p-disabled) {
          box-shadow: none;
        }
      }

      &.p-highlight {
        border: 1px solid $color-light-grey;

        .p-tabview-nav-link {
          color: $color-main-text;
          background-color: $color-white;
          border-color: $color-light-grey2;
          border-radius: 6px;
        }
      }

      &:hover {
        &:not(.p-highlight):not(.p-disabled) {
          .p-tabview-nav-link {
            border: 1px solid $color-white;
            background-color: $color-white;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .p-tabview-panels {
    padding: 1.25rem 0;
  }
}
