p-accordion {
  .data-table {
    &.card {
      border: 0;
    }
  }

  .p-accordion .p-accordion-content {
    padding: 0;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    background-color: $color-white;
  }

  .p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link,
  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    background-color: $color-white;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.5rem 1rem;
    height: 60px;
  }
}
