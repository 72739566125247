.p-component.p-toast {
  opacity: 1;

  .p-toast-message {
    .p-toast-message-content {
      font-weight: normal;
      padding: 1.5rem 1rem;
    }

    &.p-toast-message-success {
      background-color: $color-success-50;
      border-color: $color-dark-green;

      .p-toast-message-icon {
        color: $color-dark-green;
      }

      .p-toast-icon-close {
        color: $color-dark-green;
      }

      .p-toast-message-content {
        color: $color-dark-green;
      }
    }

    &.p-toast-message-error {
      background-color: $color-red-50;
      border-color: $color-red-dark;

      .p-toast-message-icon {
        color: $color-red-dark;
      }

      .p-toast-icon-close {
        color: $color-red-dark;
      }

      .p-toast-message-content {
        color: $color-red-dark;
      }
    }

    &.p-toast-message-info {
      background-color: $color-primary-50;
      border-color: $color-primary-light;

      .p-toast-message-icon {
        color: $color-primary-light;
      }

      .p-toast-icon-close {
        color: $color-primary-light;
      }

      .p-toast-message-content {
        color: $color-primary-light;
      }
    }

    &.p-toast-message-warn {
      background-color: $color-yellow-50;
      border-color: $color-yellow;

      .p-toast-message-icon {
        color: $color-red-dark;
      }

      .p-toast-icon-close {
        color: $color-red-dark;
      }

      .p-toast-message-content {
        color: $color-red-dark;
      }
    }
  }
}
