.primary-bgcolor {
  background-color: $color-primary !important;
}
.secondary-bgcolor {
  background-color: $color-secondary !important;
}

.secondary-light-bgcolor {
  background-color: $color-secondary-light !important;
}

.success-bgcolor {
  background-color: $color-dark-green !important;
}

.fs-xsmall {
  font-size: 0.715rem !important;
}

.fs-small {
  font-size: 0.858rem !important;
}

.fs-normal {
  font-size: 1rem !important;
}

.fs-large {
  font-size: 1.5rem !important;
}

.fs-xlarge {
  font-size: 2rem !important;
}

.fs-xxlarge {
  font-size: 3rem !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.full-width {
  width: 100%;
}
