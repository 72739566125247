:root {
  --text-color: #1c1d21;
  --text-color-secondary: #cecfd3;
  --primary-color: #1b345b;
  --secondary-color: #237bba;
  --primary-color-text: #c9dcfa;
  --success-color: #8dd249;
  --warning-color: #fbc02d;
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.color-success {
  color: #8dd249;
}

.color-primary {
  color: #1b345b;
}

.color-error-light {
  color: #f96a63;
}

.color-error-dark {
  color: #7f2403;
}

.color-error {
  color: #b4362e;
}

.primary-bgcolor {
  background-color: #1b345b !important;
}

.secondary-bgcolor {
  background-color: #237bba !important;
}

.secondary-light-bgcolor {
  background-color: #0080d0 !important;
}

.success-bgcolor {
  background-color: #195419 !important;
}

.fs-xsmall {
  font-size: 0.715rem !important;
}

.fs-small {
  font-size: 0.858rem !important;
}

.fs-normal {
  font-size: 1rem !important;
}

.fs-large {
  font-size: 1.5rem !important;
}

.fs-xlarge {
  font-size: 2rem !important;
}

.fs-xxlarge {
  font-size: 3rem !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.full-width {
  width: 100%;
}

body {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  margin: 0;
  color: #1c1d21;
  font-weight: 400;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "DM Sans", sans-serif !important;
}

.p-component,
.p-inputtext {
  font-family: "Roboto", sans-serif !important;
}

.color-primary {
  color: #1b345b;
}

.color-secondary {
  color: #237bba;
}

.color-error {
  color: #b4362e;
}

.color-success {
  color: #8dd249;
}

.no-wrap {
  white-space: nowrap;
}

.p-avatar.portrait img {
  width: 100% !important;
  height: auto !important;
}

.p-avatar.landscape img {
  width: auto !important;
  height: 100% !important;
}

.p-badge {
  background-color: #edeff1;
}
.p-badge.p-badge-info {
  background-color: #237bba;
}
.p-badge.default {
  margin-left: 8px;
  font-size: 12px;
  background-color: #edeff1;
  color: #1c1d21;
}
.p-badge.no-bg {
  margin-left: 8px;
  font-size: 12px;
  background-color: transparent;
  color: #1c1d21;
}
.p-badge.no-bg.p-badge-info {
  color: #237bba;
}

.p-tabview {
  color: #1b345b;
}
.p-tabview .p-tabview-nav-content {
  overflow-x: initial;
  overflow-y: initial;
}
.p-tabview .p-tabview-nav-container {
  width: fit-content;
}
.p-tabview .p-tabview-nav {
  border-radius: 6px;
  border: 1px solid #edeff1;
  background-color: #edeff1;
  padding: 1px;
}
.p-tabview .p-tabview-nav > li {
  background: #edeff1;
  border: 1px solid transparent;
  border-radius: 6px;
}
.p-tabview .p-tabview-nav > li:not(:last-child) {
  margin-right: 1px;
}
.p-tabview .p-tabview-nav > li .p-tabview-nav-link {
  color: #70737b;
  height: 36px;
  padding: 8px 24px;
  font-size: 14px;
  line-height: 20px;
  background: #edeff1;
  border-width: 1px;
  border-color: #edeff1;
  outline: none;
  margin: 0;
  border-radius: 0;
}
.p-tabview .p-tabview-nav > li .p-tabview-nav-link:focus:not(.p-disabled) {
  box-shadow: none;
}
.p-tabview .p-tabview-nav > li.p-highlight {
  border: 1px solid #cecfd3;
}
.p-tabview .p-tabview-nav > li.p-highlight .p-tabview-nav-link {
  color: #1c1d21;
  background-color: rgb(255, 255, 255);
  border-color: #edeff1;
  border-radius: 6px;
}
.p-tabview .p-tabview-nav > li:hover:not(.p-highlight):not(.p-disabled) .p-tabview-nav-link {
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
}
.p-tabview .p-tabview-panels {
  padding: 1.25rem 0;
}

.p-button {
  color: rgb(255, 255, 255);
  background-color: #1b345b;
  border-color: #1b345b;
  font-size: 1.1rem;
}
.p-button:enabled:hover {
  background-color: #0a537b;
  border-color: #0a537b;
}
.p-button.p-button-text {
  color: #1b345b;
  border-color: transparent;
  background-color: transparent;
}
.p-button.p-button-danger {
  background-color: #b4362e;
  border-color: #b4362e;
}
.p-button.p-button-danger:enabled:hover {
  background-color: #f96a63;
  border-color: #f96a63;
}
.p-button.p-button-danger.p-button-text {
  color: #b4362e;
  border-color: transparent;
  background-color: transparent;
}
.p-button.p-button-secondary {
  background-color: #237bba;
  border-color: #237bba;
}
.p-button.p-button-secondary:enabled:hover {
  background-color: #0080d0;
  border-color: #0080d0;
}
.p-button.p-button-secondary.p-button-text {
  color: #237bba;
  border-color: transparent;
  background-color: transparent;
}
.p-button.p-button-success {
  background-color: #195419;
  border-color: #195419;
}
.p-button.p-button-success:enabled:hover {
  background-color: #8dd249;
  border-color: #8dd249;
}
.p-button.p-button-success.p-button-text {
  color: #195419;
  border-color: transparent;
  background-color: transparent;
}
.p-button.p-button-icon-only.p-button-rounded.action-btn .pi {
  font-size: 1.3rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #1b345b;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: #0a537b;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background-color: rgb(255, 255, 255);
  border-color: #1b345b;
}
.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background-color: #1b345b;
  border-color: #1b345b;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background-color: rgb(255, 255, 255);
  border-color: #0a537b;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover .p-radiobutton-icon {
  background-color: #0a537b;
  border-color: #0a537b;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  background-color: rgb(255, 255, 255);
  border-color: #0a537b;
}

.p-button.p-button-icon-only.p-button-rounded {
  width: 2rem;
  height: 2rem;
  padding: 1rem;
}

form .p-inputtext,
form .p-password-input {
  border-radius: 0.5rem;
}
form .p-inputtext.readonly,
form .p-password-input.readonly {
  color: #70737b;
  outline: none !important;
  background-color: #edeff1;
  border-color: #cecfd3;
}
form .p-inputtext.readonly:hover,
form .p-password-input.readonly:hover {
  border-color: #cecfd3;
  outline: none !important;
  box-shadow: none !important;
}
form .p-inputtext:focus:enabled,
form .p-password-input:focus:enabled {
  outline: none !important;
  box-shadow: none !important;
}
form .p-inputtext.ng-invalid.ng-touched.ng-dirty,
form .p-password-input.ng-invalid.ng-touched.ng-dirty {
  border-color: #b4362e;
}
form .ui-message.ui-message-error {
  color: #b4362e;
  font-size: 0.8rem;
}
form .ui-errors {
  padding: 0 1rem;
}
form .ui-errors > li:first-letter {
  text-transform: capitalize;
}
form .radio-group {
  padding: 0.5rem;
}
form .radio-group .radio-row {
  padding: 0.25rem 0;
}
form .radio-group.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
form .radio-group.horizontal > *:not(:first-of-type) {
  margin: 0 0 0 1rem;
}

.pi-bell .p-badge-dot {
  width: 0.5rem;
  height: 0.5rem;
  min-height: 0.5rem;
  min-width: 0.5rem;
  right: 3px;
}

.p-field .p-inputtextarea {
  min-height: 200px;
}
.p-field :not(.p-inputgroup) .p-component.p-inputtext, .p-field :not(.p-inputgroup) .p-component.p-password-input {
  width: 100%;
}
.p-field :not(.p-inputgroup) .p-component.p-dropdown {
  width: 100%;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  color: #1b345b;
  background: #1b345b;
  border: none;
}

.card {
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.overview-box .overview-status {
  font-weight: 500;
  border-radius: 2px;
  color: rgb(255, 255, 255);
}

.widget-list {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style-type: none;
}
.widget-list > li {
  border-bottom: 1px solid #cecfd3;
}
.widget-list > li:last-child {
  border-bottom: 0 none;
}
.widget-list > li .person-item img {
  width: 2.5rem;
  height: 2.5rem;
}
.widget-list > li .widget-list-item-radius,
.widget-list > li .person-tag {
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}
.widget-list > li .p-progressbar {
  height: 6px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
}
.widget-list > li .p-progressbar-value {
  background: #fbc02d;
}

.widget-expenses .card-subheader {
  font-size: 0.9rem;
  color: #237bba;
  border-bottom: 1px solid #cecfd3;
}
.widget-expenses .item {
  padding: 0.5rem;
  border-bottom: 1px solid #cecfd3;
}
.widget-expenses .item:last-child {
  border: 0 none;
}
.widget-expenses .item .type {
  color: #0097a7;
  font-size: 1.5rem;
}
.widget-expenses .item .value {
  font-weight: 500;
}
.widget-expenses .item .subtext {
  color: #237bba;
}
.widget-expenses .item .item-button a {
  color: #237bba;
}

.widget-traffic .left {
  padding: 1rem;
}
.widget-traffic .left .total .title {
  color: #237bba;
}
.widget-traffic .left .total .value {
  font-size: 2rem;
  font-weight: 500;
}
.widget-traffic .left .info .title {
  color: #237bba;
}
.widget-traffic .left .info .value {
  font-weight: 500;
}
.widget-traffic .left .info .percent {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
}
.widget-traffic .left .info .percent i.type-green {
  color: #0097a7;
}
.widget-traffic .left .info .percent i.type-orange {
  color: #fbc02d;
}
.widget-traffic .left .info .percent i.type-gray {
  color: #455a64;
}

.widget-overlay {
  position: relative;
}
.widget-overlay .overlay-header {
  position: relative;
  height: 20rem;
  top: -3rem;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.widget-overlay .description {
  line-height: 1.5;
}

.widget-topsearchs .item {
  padding: 1rem;
  border-bottom: 1px solid #cecfd3;
}
.widget-topsearchs .item .value {
  font-weight: 500;
}
.widget-topsearchs .item .value.type-green {
  color: #8dd249;
}
.widget-topsearchs .item .value.type-yellow {
  color: #fbc02d;
}
.widget-topsearchs .item .value.type-pink {
  color: #1b345b;
}
.widget-topsearchs .item:nth-child(even) {
  background-color: rgb(255, 255, 255);
}
.widget-topsearchs .item:last-child {
  border-bottom: 0 none;
}

.widget-timeline .p-timeline.p-timeline-vertical .p-timeline-event-opposite {
  flex: 0;
  padding: 0;
}
.widget-timeline .p-timeline-event-separator .marker-icon {
  color: white;
}
.widget-timeline .p-timeline-event-separator .custom-marker {
  border-radius: 4px;
}

.widget-activity {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style-type: none;
}
.widget-activity > li {
  padding: 1rem 0;
  border-bottom: 1px solid #cecfd3;
}
.widget-activity > li:last-child {
  border-bottom: 0 none;
}
.widget-activity > li:nth-child(1) .p-progressbar-value {
  background: #fbc02d;
}
.widget-activity > li:nth-child(2) .p-progressbar-value {
  background: #1b345b;
}
.widget-activity > li:nth-child(3) .p-progressbar-value {
  background: #8dd249;
}
.widget-activity > li:nth-child(4) .p-progressbar-value {
  background: #8dd249;
}
.widget-activity > li:nth-child(5) .p-progressbar-value {
  background: #8dd249;
}
.widget-activity > li:nth-child(6) .p-progressbar-value {
  background: #1b345b;
}
.widget-activity > li .activity-item .activity-title {
  font-weight: 500;
}
.widget-activity > li .activity-item .activity-subtext {
  font-size: 0.85rem;
  color: #237bba;
}
.widget-activity > li .activity-item .p-progressbar {
  height: 6px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
}

.widget-bestsellers {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style-type: none;
}
.widget-bestsellers > li {
  padding: 1rem 0;
}
.widget-bestsellers > li:last-child {
  border-bottom: 0 none;
}
.widget-bestsellers > li .bestseller-item {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  height: 3.5rem;
  transition: box-shadow 0.2s;
}
.widget-bestsellers > li .bestseller-item img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.widget-bestsellers > li .bestseller-item a {
  color: #237bba;
}
.widget-bestsellers > li .bestseller-item .item-button {
  margin-left: auto;
}
.widget-bestsellers > li .bestseller-item:hover {
  cursor: pointer;
  background-color: #0080d0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.p-dialog {
  max-height: 100% !important;
}
.p-dialog .p-dialog-content {
  height: 100%;
}

.p-toolbar {
  background-color: rgb(255, 255, 255);
  border: 0;
}

.file-type-icon {
  width: 32px;
  height: 38px;
  min-width: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.file-type-icon.size-sm {
  width: 16px;
  height: 24px;
  min-width: 16px;
}
.file-type-icon.type-doc, .file-type-icon.type-docx {
  background-image: url("~assets/icons/doc.svg");
}
.file-type-icon.type-image, .file-type-icon.type-jpg, .file-type-icontype-jpeg {
  background-image: url("~assets/icons/jpg.svg");
}
.file-type-icon.type-xls, .file-type-icon.type-xlsx, .file-type-icon.type-csv {
  background-image: url("~assets/icons/xls.svg");
}
.file-type-icon.type-png {
  background-image: url("~assets/icons/png.svg");
}
.file-type-icon.type-pdf {
  background-image: url("~assets/icons/pdf.svg");
}
.file-type-icon.type-txt {
  background-image: url("~assets/icons/documents.svg");
}
.file-type-icon.type-other {
  background-image: url("~assets/icons/checklist.svg");
}
.file-type-icon.type-tiff {
  background-image: url("~assets/icons/jpg.svg");
}
.file-type-icon.type-ppt {
  background-image: url("~assets/icons/ppt.svg");
}
.file-type-icon.type-zip {
  background-image: url("~assets/icons/zip.svg");
}

.p-datatable .p-datatable-tbody > tr .cell-wrapper {
  color: #141618 !important;
}

.data-table.card {
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: none;
  border: 1px solid #cecfd3;
}
.data-table .p-datatable,
.data-table .p-treetable {
  border: 0;
  padding: 0;
}
.data-table .p-datatable .p-datatable-loading-overlay,
.data-table .p-datatable .p-treetable-loading-overlay,
.data-table .p-treetable .p-datatable-loading-overlay,
.data-table .p-treetable .p-treetable-loading-overlay {
  background-color: rgba(255, 255, 255, 0.3);
}
.data-table .p-datatable .p-datatable-loading-overlay .p-datatable-loading-icon,
.data-table .p-datatable .p-datatable-loading-overlay .p-treetable-loading-icon,
.data-table .p-datatable .p-treetable-loading-overlay .p-datatable-loading-icon,
.data-table .p-datatable .p-treetable-loading-overlay .p-treetable-loading-icon,
.data-table .p-treetable .p-datatable-loading-overlay .p-datatable-loading-icon,
.data-table .p-treetable .p-datatable-loading-overlay .p-treetable-loading-icon,
.data-table .p-treetable .p-treetable-loading-overlay .p-datatable-loading-icon,
.data-table .p-treetable .p-treetable-loading-overlay .p-treetable-loading-icon {
  color: #1b345b;
  font-weight: bold;
}
.data-table .p-datatable .p-toolbar,
.data-table .p-treetable .p-toolbar {
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: none;
}
.data-table .p-datatable .p-treetable-header,
.data-table .p-datatable .p-datatable-header,
.data-table .p-treetable .p-treetable-header,
.data-table .p-treetable .p-datatable-header {
  background: rgb(255, 255, 255);
  padding: 0;
}
.data-table .p-datatable .p-datatable-header,
.data-table .p-datatable .p-paginator-bottom,
.data-table .p-treetable .p-datatable-header,
.data-table .p-treetable .p-paginator-bottom {
  border-width: 0;
  border-radius: 0.5rem;
}
.data-table .p-datatable .p-datatable-tbody td:not(.empty-wrapper),
.data-table .p-treetable .p-datatable-tbody td:not(.empty-wrapper) {
  padding: 0.5rem 1rem;
}
.data-table .p-datatable .p-treetable-tbody td,
.data-table .p-treetable .p-treetable-tbody td {
  padding: 0.5rem 1rem;
  color: #1c1d21 !important;
  line-height: 1.25rem;
  font-size: 0.875rem;
  min-height: 65px;
}
.data-table .p-datatable .p-treetable-tbody td button,
.data-table .p-treetable .p-treetable-tbody td button {
  line-height: 1.25rem;
  font-size: 0.875rem;
}
.data-table .p-datatable .p-datatable-thead th,
.data-table .p-datatable .p-treetable-thead th,
.data-table .p-treetable .p-datatable-thead th,
.data-table .p-treetable .p-treetable-thead th {
  font-size: 0.75rem;
  color: #70737b;
  text-transform: uppercase;
  border-color: #cecfd3;
}
.data-table .p-datatable .p-datatable-thead th:hover .p-sortable-column-icon.pi-sort-alt,
.data-table .p-datatable .p-treetable-thead th:hover .p-sortable-column-icon.pi-sort-alt,
.data-table .p-treetable .p-datatable-thead th:hover .p-sortable-column-icon.pi-sort-alt,
.data-table .p-treetable .p-treetable-thead th:hover .p-sortable-column-icon.pi-sort-alt {
  opacity: 1;
}
.data-table .p-datatable .cell-wrapper,
.data-table .p-treetable .cell-wrapper {
  color: #1c1d21 !important;
  line-height: 1.25rem;
  font-size: 0.875rem;
}
.data-table .p-datatable .cell-header-wrapper,
.data-table .p-treetable .cell-header-wrapper {
  font-size: 0.75rem;
  color: #70737b;
  text-transform: uppercase;
  border-color: #cecfd3;
}
.data-table .p-datatable .cell-header-wrapper .p-column-filter,
.data-table .p-treetable .cell-header-wrapper .p-column-filter {
  margin-left: -0.75rem;
}
.data-table .p-datatable .cell-header-wrapper .p-sortable-column-icon,
.data-table .p-treetable .cell-header-wrapper .p-sortable-column-icon {
  font-size: 0.75rem;
  color: #70737b;
}
.data-table .p-datatable .cell-header-wrapper .p-sortable-column-icon.pi-sort-alt,
.data-table .p-treetable .cell-header-wrapper .p-sortable-column-icon.pi-sort-alt {
  opacity: 0;
}
.data-table .p-datatable .cell-header-wrapper,
.data-table .p-datatable .cell-wrapper,
.data-table .p-treetable .cell-header-wrapper,
.data-table .p-treetable .cell-wrapper {
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  align-items: center;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.5);
}
.data-table .p-datatable .cell-header-wrapper.nowrap,
.data-table .p-datatable .cell-wrapper.nowrap,
.data-table .p-treetable .cell-header-wrapper.nowrap,
.data-table .p-treetable .cell-wrapper.nowrap {
  flex-wrap: nowrap;
  white-space: nowrap;
}
.data-table .p-datatable .cell-header-wrapper.align-center,
.data-table .p-datatable .cell-wrapper.align-center,
.data-table .p-treetable .cell-header-wrapper.align-center,
.data-table .p-treetable .cell-wrapper.align-center {
  justify-content: center;
}
.data-table .p-datatable .cell-header-wrapper.align-left,
.data-table .p-datatable .cell-wrapper.align-left,
.data-table .p-treetable .cell-header-wrapper.align-left,
.data-table .p-treetable .cell-wrapper.align-left {
  justify-content: flex-start;
}
.data-table .p-datatable .cell-header-wrapper.align-right,
.data-table .p-datatable .cell-wrapper.align-right,
.data-table .p-treetable .cell-header-wrapper.align-right,
.data-table .p-treetable .cell-wrapper.align-right {
  justify-content: flex-end;
}
.data-table .p-datatable .empty-wrapper,
.data-table .p-treetable .empty-wrapper {
  padding: 3rem 2rem;
}
.data-table .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background-color: #eaf2ff;
}

p-accordion .data-table.card {
  border: 0;
}
p-accordion .p-accordion .p-accordion-content {
  padding: 0;
}
p-accordion .p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: rgb(255, 255, 255);
}
p-accordion .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
p-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background-color: rgb(255, 255, 255);
}
p-accordion .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.5rem 1rem;
  height: 60px;
}

:root {
  --text-color: #1c1d21;
  --text-color-secondary: #cecfd3;
  --primary-color: #1b345b;
  --secondary-color: #237bba;
  --primary-color-text: #c9dcfa;
  --success-color: #8dd249;
  --warning-color: #fbc02d;
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.color-success {
  color: #8dd249;
}

.color-primary {
  color: #1b345b;
}

.color-error-light {
  color: #f96a63;
}

.color-error-dark {
  color: #7f2403;
}

.color-error {
  color: #b4362e;
}

.p-chip {
  margin: 0 0.25rem 0.5rem;
  background: rgb(255, 255, 255);
  border: 1px solid #cecfd3;
  border-radius: 0.5rem;
}

.p-component.p-dropdown-panel {
  max-width: 400px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: #eaf2ff;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: #eaf2ff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background-color: #eaf2ff;
}

.p-paginator.p-component {
  justify-content: flex-end;
  font-size: 0.875rem;
  color: #1c1d21 !important;
  border: none;
}
.p-paginator.p-component .p-paginator-next,
.p-paginator.p-component .p-paginator-prev {
  min-width: 2rem;
  height: 2rem;
}
.p-paginator.p-component .p-paginator-last.p-paginator-element,
.p-paginator.p-component .p-paginator-first.p-paginator-element {
  display: none;
}
.p-paginator.p-component .p-dropdown {
  height: 2rem;
}
.p-paginator.p-component .p-dropdown .p-dropdown-label {
  padding-top: 0.5rem;
}
.p-paginator.p-component .p-element {
  font-size: 0.875rem;
  color: #1c1d21 !important;
}
.p-paginator.p-component .p-element.p-paginator-page {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: none;
}

.p-component.p-toast {
  opacity: 1;
}
.p-component.p-toast .p-toast-message .p-toast-message-content {
  font-weight: normal;
  padding: 1.5rem 1rem;
}
.p-component.p-toast .p-toast-message.p-toast-message-success {
  background-color: #fafef6;
  border-color: #195419;
}
.p-component.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: #195419;
}
.p-component.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #195419;
}
.p-component.p-toast .p-toast-message.p-toast-message-success .p-toast-message-content {
  color: #195419;
}
.p-component.p-toast .p-toast-message.p-toast-message-error {
  background-color: #fef6f6;
  border-color: #7f2403;
}
.p-component.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
  color: #7f2403;
}
.p-component.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #7f2403;
}
.p-component.p-toast .p-toast-message.p-toast-message-error .p-toast-message-content {
  color: #7f2403;
}
.p-component.p-toast .p-toast-message.p-toast-message-info {
  background-color: #f7faff;
  border-color: #0a537b;
}
.p-component.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon {
  color: #0a537b;
}
.p-component.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #0a537b;
}
.p-component.p-toast .p-toast-message.p-toast-message-info .p-toast-message-content {
  color: #0a537b;
}
.p-component.p-toast .p-toast-message.p-toast-message-warn {
  background-color: #fefcf6;
  border-color: #fbc02d;
}
.p-component.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon {
  color: #7f2403;
}
.p-component.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #7f2403;
}
.p-component.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-content {
  color: #7f2403;
}