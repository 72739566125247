.file-type-icon {
  width: 32px;
  height: 38px;
  min-width: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.size-sm {
    width: 16px;
    height: 24px;
    min-width: 16px;
  }

  &.type-doc,
  &.type-docx {
    background-image: url('~assets/icons/doc.svg');
  }
  &.type-image,
  &.type-jpg,
  &type-jpeg {
    background-image: url('~assets/icons/jpg.svg');
  }
  &.type-xls,
  &.type-xlsx,
  &.type-csv {
    background-image: url('~assets/icons/xls.svg');
  }
  &.type-png {
    background-image: url('~assets/icons/png.svg');
  }
  &.type-pdf {
    background-image: url('~assets/icons/pdf.svg');
  }
  &.type-txt {
    background-image: url('~assets/icons/documents.svg');
  }
  &.type-other {
    background-image: url('~assets/icons/checklist.svg');
  }
  &.type-tiff {
    background-image: url('~assets/icons/jpg.svg');
  }
  &.type-ppt {
    background-image: url('~assets/icons/ppt.svg');
  }
  &.type-zip {
    background-image: url('~assets/icons/zip.svg');
  }
}
