form {
  .p-inputtext,
  .p-password-input {
    border-radius: 0.5rem;

    &.readonly {
      color: $color-secondary-text;
      outline: none !important;
      background-color: $color-light-grey2;
      border-color: $color-light-grey;

      &:hover {
        border-color: $color-light-grey;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    &:focus {
      &:enabled {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  .p-inputtext.ng-invalid.ng-touched.ng-dirty,
  .p-password-input.ng-invalid.ng-touched.ng-dirty {
    border-color: $color-red;
  }
  .ui-message.ui-message-error {
    color: $color-red;
    font-size: 0.8rem;
  }

  .ui-errors {
    padding: 0 1rem;
    > li {
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  .radio-group {
    padding: 0.5rem;

    .radio-row {
      padding: 0.25rem 0;
    }

    &.horizontal {
      display: flex;
      flex-direction: row;
      align-items: center;

      > * {
        &:not(:first-of-type) {
          margin: 0 0 0 1rem;
        }
      }
    }
  }
}

.pi-bell {
  .p-badge-dot {
    width: 0.5rem;
    height: 0.5rem;
    min-height: 0.5rem;
    min-width: 0.5rem;
    right: 3px;
  }
}

.p-field {
  .p-inputtextarea {
    min-height: 200px;
  }
  :not(.p-inputgroup) {
    .p-component {
      &.p-inputtext,
      &.p-password-input {
        width: 100%;
      }
      &.p-dropdown {
        width: 100%;
      }
    }
  }
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  color: $color-primary;
  background: $color-primary;
  border: none;
}
