.p-datatable .p-datatable-tbody > tr {
  .cell-wrapper {
    color: $color-primary-bg !important;
  }
}

.data-table {
  &.card {
    padding: 0;
    border-radius: 0.5rem;
    box-shadow: none;
    border: 1px solid $color-light-grey;
  }
  .p-datatable,
  .p-treetable {
    border: 0;
    padding: 0;
    .p-datatable-loading-overlay,
    .p-treetable-loading-overlay {
      background-color: rgba(255, 255, 255, 0.3);

      .p-datatable-loading-icon,
      .p-treetable-loading-icon {
        color: $color-primary;
        font-weight: bold;
      }
    }

    .p-toolbar {
      width: 100%;
      background-color: $color-white;
      border: none;
    }

    .p-treetable-header,
    .p-datatable-header {
      background: $color-white;
      padding: 0;
    }

    .p-datatable-header,
    .p-paginator-bottom {
      border-width: 0;
      border-radius: 0.5rem;
    }

    .p-datatable-tbody {
      td:not(.empty-wrapper) {
        padding: 0.5rem 1rem;
      }
    }

    .p-treetable-tbody {
      td {
        padding: 0.5rem 1rem;
        color: $color-main-text !important;
        line-height: 1.25rem;
        font-size: 0.875rem;
        min-height: 65px;

        button {
          line-height: 1.25rem;
          font-size: 0.875rem;
        }
      }
    }

    .p-datatable-thead,
    .p-treetable-thead {
      th {
        font-size: 0.75rem;
        color: $color-secondary-text;
        text-transform: uppercase;
        border-color: $color-light-grey;

        &:hover {
          .p-sortable-column-icon {
            &.pi-sort-alt {
              opacity: 1;
            }
          }
        }
      }
    }

    .cell-wrapper {
      color: $color-main-text !important;
      line-height: 1.25rem;
      font-size: 0.875rem;
    }

    .cell-header-wrapper {
      font-size: 0.75rem;
      color: $color-secondary-text;
      text-transform: uppercase;
      border-color: $color-light-grey;

      .p-column-filter {
        margin-left: -0.75rem;
      }

      .p-sortable-column-icon {
        font-size: 0.75rem;
        color: $color-secondary-text;

        &.pi-sort-alt {
          opacity: 0;
        }

        &.pi-sort-amount-down-alt {
        }

        &.pi-sort-amount-up-alt {
        }
      }
    }

    .cell-header-wrapper,
    .cell-wrapper {
      display: flex;
      flex-wrap: wrap;
      white-space: normal;
      align-items: center;
      text-overflow: ellipsis;

      color: rgba(0, 0, 0, 0.5);

      &.nowrap {
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      &.align-center {
        justify-content: center;
      }

      &.align-left {
        justify-content: flex-start;
      }

      &.align-right {
        justify-content: flex-end;
      }
    }

    .empty-wrapper {
      padding: 3rem 2rem;
    }
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover {
    background-color: $color-primary-100;
  }
}
