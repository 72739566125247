.p-button {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
  font-size: 1.1rem;

  &:enabled:hover {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
  }

  &.p-button-text {
    color: $color-primary;
    border-color: transparent;
    background-color: transparent;
  }

  &.p-button-danger {
    background-color: $color-red;
    border-color: $color-red;

    &:enabled:hover {
      background-color: $color-red-light;
      border-color: $color-red-light;
    }

    &.p-button-text {
      color: $color-red;
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.p-button-secondary {
    background-color: $color-secondary;
    border-color: $color-secondary;

    &:enabled:hover {
      background-color: $color-secondary-light;
      border-color: $color-secondary-light;
    }

    &.p-button-text {
      color: $color-secondary;
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.p-button-success {
    background-color: $color-dark-green;
    border-color: $color-dark-green;

    &:enabled:hover {
      background-color: $color-success-light;
      border-color: $color-success-light;
    }

    &.p-button-text {
      color: $color-dark-green;
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.p-button-icon-only.p-button-rounded {
    &.action-btn {
      .pi {
        font-size: 1.3rem;
      }
    }
  }
}

.p-inputswitch {
  &.p-inputswitch-checked .p-inputswitch-slider {
    background-color: $color-primary;
  }

  &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: $color-primary-light;
  }
}

.p-radiobutton {
  .p-radiobutton-box.p-highlight {
    background-color: $color-white;
    border-color: $color-primary;

    .p-radiobutton-icon {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background-color: $color-white;
    border-color: $color-primary-light;

    .p-radiobutton-icon {
      background-color: $color-primary-light;
      border-color: $color-primary-light;
    }
  }

  .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    background-color: $color-white;
    border-color: $color-primary-light;
  }
}

.p-button.p-button-icon-only.p-button-rounded {
  width: 2rem;
  height: 2rem;
  padding: 1rem;
}
