.p-badge {
  background-color: $color-light-grey2;

  &.p-badge-info {
    background-color: $color-secondary;
  }

  &.default {
    margin-left: 8px;
    font-size: 12px;
    background-color: $color-light-grey2;
    color: $color-main-text;
  }
  &.no-bg {
    margin-left: 8px;
    font-size: 12px;
    background-color: transparent;
    color: $color-main-text;

    &.p-badge-info {
      color: $color-secondary;
    }
  }
}
